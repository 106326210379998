@font-face {
    font-family: 'Golos', sans-serif;
    src: url("font/golostext/Golos_Text_Black.ttf") format("ttf"),
    url("font/golostext/Golos_Text_Bold.ttf") format("ttf"),
    url("font/golostext/Golos_Text_DemiBold.ttf") format("ttf"),
    url("font/golostext/Golos_Text_Medium.ttf") format("ttf"),
    url("font/golostext/Golos_Text_Regular.ttf") format("ttf");
}

:root {
    --theme: "";
    --grey: rgba(243, 244, 248, 1);
    --black-text: rgba(5, 0, 56, 1);
    --blue: rgba(66, 98, 255, 1);
    --gray-violet: rgba(96, 93, 129, 1);
    --orange: rgba(250, 199, 17, 1);
    --border: #d9d8e1;
    --red: #dc2f2f;
    --green: #32de84;
}

input::placeholder {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    color: rgba(205, 204, 215, 1);
}

textarea,
input:not([type="radio"]),
input:not([type="checkbox"]),
select {
    padding: 14px 20px;
    background: white;
    border-radius: 8px;
    font-weight: 400;
    display: block;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

.clr-orange {
    color: var(--orange);
}

.clr-green {
    color: var(--green);
}

.cursor-pointer {
    cursor: pointer;
}

.clr-blue {
    color: var(--blue);
}

.clr-red {
    color: var(--red);
}

.clr-gray-violet {
    color: var(--gray-violet);
}
.text-underline {
    text-decoration: underline;
}

.agree_terms {
    color: white;
    margin-top: 24px;
    text-align: center;
    cursor: pointer;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
}

textarea,
input:not([type="radio"]),
input:not([type="checkbox"]) {
    width: calc(100% - 40px);
}

input:focus {
    border: none;
    outline: none;
}

input[type="radio"],
input[type="checkbox"] {
    width: auto;
}

input[type="radio"] + label {
    cursor: pointer;
}

.styled-select select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
}

.styled-select {
    position: relative;
}

.styled-select .icon-arrow {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
}

.styled-select select {
    padding-right: 40px;
}

button, .btn {
    background-color: var(--blue);
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    /*line-height: 28px;*/
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid var(--blue);
    padding: 12px 20px;
    border-radius: 100px;
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.btn-revert {
    background-color: white;
    color: var(--blue);
    border-width: 2px;
}

.btn-revert:hover {
    background-color: var(--blue);
    color: white;
}

button svg, .btn svg {
    margin-left: 12px;
}

body {
    color: var(--black-text);
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

img {
    max-width: 100%;
}

.container {
    width: 1226px;
    max-width: 100%;
    margin: 0 auto;
}

.row {
    max-width: 985px;
    margin: 0 auto;
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Golos', sans-serif;
    font-style: normal;
    font-weight: bold;
    margin: 0 0 20px 0;
}

h1, .h1 {
    line-height: 42px;
    font-size: 36px;
}

h2, .h2 {
    line-height: 38px;
    font-size: 32px;
}

h3, .h3, h4, .h4 {
    line-height: 36px;
    font-size: 30px;
    font-weight: 600;

}

h5, .h5 {
    font-size: 28px;
    line-height: 32px;
}

.text-center {
    text-align: center;
}

header {
    padding: 10px 0px;
    position: sticky;
    background: white;
    z-index: 5;
    top: 0;
}

header .logo {
    width: 180px;
    height: 60px;
}

.header-container .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-container nav {
    display: flex;
    align-items: center;
    list-style: none;
}

.header-container nav li {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px 12px;
    cursor: pointer;
}

.select-custom {
    position: relative;
    width: max-content;

}

.select-custom li.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.select__head {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
}

.select__head svg {
    margin-right: 6px;
}

.select__body {
    text-transform: uppercase;
    right: 0;
    left: 0;
    text-align: center;
    position: absolute;
    top: 100%;
    background: white;
    border-radius: 4px;
    z-index: 3;
    list-style: none;
    padding: 5px;
    font-size: 14px;
    margin: 0px;
    box-shadow: 0px 0px 5px rgb(217 217 219);
}

.select__body li {
    cursor: pointer;
}

.select__body li .disabled {
    pointer-events: none;
    opacity: 0.4;
}

.nav-controls {
    display: flex;
    align-items: center;
}

.nav-controls .btn {
    margin-left: 24px;
}

.owl-carousel {
    position: static !important;
}

.owl-nav {
    position: absolute;
    top: 0;
    right: 0;
}

.owl-theme .owl-nav {
    margin-top: 0px;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
    line-height: 52px !important;

}

.owl-carousel .owl-nav button {
    width: 52px;
    height: 52px;
    background: white !important;
    border-radius: 50% !important;
    text-align: center;
    position: relative;
}

.owl-theme .owl-dots,
.owl-carousel .owl-nav {
    width: 985px;
    /*margin: 0 auto;*/
    text-align: right !important;
    left: 0;
    /*right: 0;*/
}

.owl-theme .owl-dots {
    text-align: center !important;
    margin-top: 64px;
}

.owl-carousel .owl-nav button svg {
    margin-left: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: 0;
}

.owl-theme .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
    background: white !important;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--blue) !important;
}


footer {
    border-top: 1px solid var(--blue);
    padding: 24px 0px;
    background: linear-gradient(111.92deg, #050038 0%, #050038 52.74%, #050038 100%);
}

footer .logo path {
    fill: white;
}

.footerTop {
    display: grid;
    grid-template-columns: 60% 35%;
    justify-content: space-between;
    align-items: flex-start;
}

.footerTop nav {
    list-style: none;
    padding: 0px;
    margin: 0px;
    color: white;
    display: flex;
    flex-wrap: wrap;
}

.footerTop .navigation {
    display: flex;
    flex-wrap: wrap;
}

.footerTop nav li {
    margin: 5px 12px 5px 0;
    cursor: pointer;
    font-size: 16px;
    color: white;
    transition: color 0.95ms;
}

.footerTop nav li:hover,
.footerTop nav li.active {
    color: var(--orange);
}

.contact {
    font-family: 'Open Sans', sans-serif;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.contact li a,
.contact li {
    font-family: 'Open Sans', sans-serif;
    color: white;
    text-decoration: none;
    /*cursor: po;*/
}

.fs-16 {
    font-size: 16px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-45 {
    font-size: 45px;
    line-height: 62px;
    letter-spacing: -2px;
}

.fs-25 {
    font-size: 22px;
    line-height: 28px;
    /*letter-spacing: -2px;*/
}

.street {
    margin-top: 22px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.01em;
    text-align: left;
}

.social-link {
    display: flex;
    list-style: none;
    margin: 0px;
    padding: 0px;
    align-items: center;
    justify-content: flex-end;
}

.social-link li {
    margin: 0 0 0 12px;
}

.footerBottom {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 60% 35%;
    /*justify-content: space-between;*/
    align-items: center;

}

.footerBottom * {
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: -0.01em;
    /*text-align: left;*/

}

/*------------------    FEEDBACK    FORM    ---------------*/
.section_feedback {
    background: linear-gradient(111.92deg, #050038 0%, #050038 52.74%, #050038 100%);
    position: relative;
    overflow: hidden;
    padding: 88px 0px;
}

.section_feedback h3 {
    width: 80%;
    text-align: center;
    margin: 0 auto;
}

.section_feedback h3,
.section_feedback p {
    color: white;
}

.section_feedback .img {
    position: absolute;
    top: 0;
    max-width: 100%;
}

.section_feedback .content {
    position: relative;
}

.field {
    margin-bottom: 24px;
}

.field-row {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 12px));
    justify-content: space-between;
}

.form-feedback {
    max-width: 648px;
    margin: 0 auto;
    text-align: center;
}

.form-feedback button {
    margin: 0 auto;
}

.developers span:first-child {
    margin-right: 24px;
    cursor: pointer;
}

@media (max-width: 992px) {
    .row {
        padding: 0 15px;
    }


    .nav-controls .btn {
        display: none;
    }

    .owl-dots,
    .owl-carousel .owl-nav {
        display: none !important;
    }

    .footerTop {
        display: grid;
        grid-template-columns: 50% 45%;
    }
}

@media (max-width: 767px) {
    .info-block {
        padding: 15px 10px !important;
    }

    .developers span:first-child {
        margin-right: 0;
        order: 2;
    }

    .developers {
        display: flex;
        justify-content: space-between;
    }

    footer {
        padding: 24px 15px;
    }

    .fs-45 {
        font-size: 40px;
    }


    .footerBottom {
        grid-template-columns: 100%;
        grid-row-gap: 15px;
        justify-content: center;
        text-align: center;
    }

    .footerTop .navigation {
        justify-content: center;
    }

    .footerBottom .social-link {
        order: -1;
    }

    .contact li a, .contact li {
        font-family: 'Open Sans', sans-serif;
        color: white;
        text-decoration: none;
        text-align: center;
    }

    .footerTop .navigation nav.nav-hash {
        display: none;
    }

    .footerTop .nav-pages {
        margin: 15px 0;
    }

    .footerTop .nav-pages nav {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    footer .logo {
        max-height: 40px !important;
        margin: 0 auto !important;
    }

    .social-link {
        justify-content: center;
    }

    .footerTop {
        display: block;
    }

    .section_feedback {
        padding: 25px 0px;
    }

    .section_feedback h3 {
        width: 100%;
    }

    .field-row {
        display: block;
    }

    input::placeholder,
    input, select {
        font-size: 14px;
    }

    .row {
        padding: 0 12px;
    }

    .header-container nav {
        display: none;
    }

}

@media (max-width: 992px) {
    body {
        font-size: 16px;
        line-height: 18px;
    }

    h1, .h1 {
        line-height: 32px;
        font-size: 28px;
    }

    h2, .h2 {
        line-height: 30px;
        font-size: 26px;
    }

    h3, .h3, h4, .h4, h5, .h5 {
        line-height: 30px;
        font-size: 24px;
        font-weight: 600;

    }

    ul li,
    ol li {
        line-height: 22px;
    }
}

.modal-container,
.term-use__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: #0000007a;
    display: flex;
    align-items: center;
}

.modal-content {
    background: white;
    border-radius: 18px;
    max-height: 90vh;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modal-content-sm {
    width: 460px;
    max-width: 100%;
}

.term-use__body {
    background: white;
    border-radius: 24px;
    max-height: 90vh;
    margin: auto;
    overflow-y: auto;
}

.modal-content .modal-body,
.term-use__body .content {
    padding: 15px;
}

.modal-content .modal-body {
    overflow-y: auto;
}

.modal-content .modal-head,
.term-use__body .head {
    display: flex;
    justify-content: space-between;
    background: var(--black-text);
    color: white;
    padding: 15px;
}

.modal-content .modal-head .h4 {
    margin-bottom: 0;
    font-size: 20px;
}

.confirm-container {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    background: var(--blue);
    padding: 10px;
    border-radius: 8px;
    color: white;
    text-transform: uppercase;
}

.confirm-container .head {
    margin-left: auto;
    text-align: right;
}

.confirm-container.error {
    background: red;
}

.close-modal {
    cursor: pointer;
}

.term-use__body ul {
    list-style: none;
}

.section-wrap {
    display: flex;
    justify-content: center;
    padding: 5px 12px;
    flex-wrap: wrap;
}

.section-wrap .item-tab {
    color: white;
    text-transform: uppercase;
    font-size: 15px;
    padding: 5px 16px;
    position: relative;
    font-weight: 500;
    margin: 5px 0;
    cursor: pointer;
}

.section-tabs {
    background: rgb(5 0 56);
    position: sticky;
    top: 80px;
    margin-bottom: 25px;
    z-index: 4;
}


.active.item-tab:before {
    content: '';
    display: block;
    position: absolute;
    background: white;
    height: 4px;
    left: 0;
    right: 0;
    bottom: 0px;
    border-radius: 4px;
}

.info-block {
    background: var(--grey);
    border-radius: 12px;
    padding: 22px;
}

@media (max-width: 475px) {
    .section-wrap .item-tab {
        font-size: 13px;
    }
}

.table-section {
    width: max-content;
    margin: 0 auto 40px auto;
    max-width: calc(100% - 30px);
    padding: 0 15px;
}


.tablet-firms {
    overflow-y: auto;
    margin: 45px 0;
}

.tablet-firms table {
    border-radius: 20px;
    min-width: 100%;
}

table, th, td {
    border: 1px solid #E4E4E4;
    border-collapse: collapse;
    padding: 5px;
    border-radius: 20px;
    text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
}

tr th {
    font-weight: bold;
}

.more {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='16' viewBox='0 0 8 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 1L0.999999 8L7 15' stroke='%23050038' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
    display: inline-block;
    width: 12px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    transform: rotate(-90deg);
    margin-left: 5px;
}

.show.more {
    transform: rotate(90deg);
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mb-5 {
    margin-bottom: 2.5rem !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mt-5 {
    margin-top: 2.5rem !important;
}

.font-italic {
    font-style: italic;
}


a.don-pdf {
    display: flex;
    align-items: center;
    color: var(--blue);
    font-weight: 500;
    font-size: 16px;
}

a.don-pdf svg {
    display: block;
    margin: 0 5px 0 0;
    max-height: 24px;
}

.videoPlayer {
    line-height: 0px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    min-height: 220px;
}

.videoPlayer iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.player-substrate {
    margin-bottom: 25px;
}

.videoPlayer .play-error {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

@media (min-width: 768px) {
    .videoPlayer {
        border-radius: 24px;
        min-height: 550px;

    }
}

.commercial-modal-content h4 {
    display: none;
}

.content.commercial-modal-content ul {
    list-style: revert;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #4262FF;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #4262FF;
}

a.btn-register {
    font-weight: 500;
    text-transform: uppercase;
    padding: 12px 15px;
    border: 2px solid rgba(5, 0, 56, 1);
    border-radius: 24px;
    text-decoration: none;
    margin-top: 25px;
    display: inline-block;
    background: rgba(5, 0, 56, 1);
    color: white;
    font-size: 13px;
    text-align: center;
}

.rtl .info-block strong {
    display: block;
}

.rtl .info-block * {
    direction: rtl;
}

.rtl .term-use__container * {
    direction: rtl;
}

.rtl p,
.rtl h1,
.rtl h2,
.rtl h3,
.rtl h4,
.rtl h5,
.rtl .h5,
.rtl .h4,
.rtl .h3,
.rtl .h2,
.rtl .h1,
.rtl span {
    direction: rtl;
}

.form-request-pay {
    max-width: 100%;
}

.form-request-pay button {
    margin: 15px auto 0px auto;
}

.form-request-pay input,
.form-request-pay select {
    background: #e8f0fe;
}

.btn-pay {
    background: white;
    width: max-content;
    max-width: 100%;
    padding: 10px 15px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid var(--black-text);
    margin: 15px 0;
}

@media (max-width: 768px) {
    header .logo {
        width: 120px;
        height: 50px;
    }

    .section-tabs {
        top: 70px;
    }
}

ol,
ul {
    padding-left: 20px;
}

ul li:not(:last-child),
ol li:not(:last-child) {
    margin: 4px 0 8px 0;
}

.term-use__container .h5 {
    font-size: 20px;
}

@media (min-width: 768px) {
    .modal-content {
        border-radius: 24px;
    }

    .modal-content .modal-body,
    .term-use__body .content {
        padding: 24px;
    }

    .modal-content .modal-head,
    .term-use__body .head {
        padding: 24px 15px;
    }

}

@media (min-width: 1024px) {
    .info-block {
        padding: 48px;
        border-radius: 24px;
    }
}

footer {
    overflow: hidden;
}

footer .logo {
    cursor: pointer;
    width: 120px;
    max-height: 60px;
    flex: 0 0 140px;
    transform: scale(1.2) translate(0px, 0px);
    display: flex;
}
footer .logo polygon{
    fill: white;
}

footer .nav-pages {
    position: relative;
    z-index: 1;
}

.contact li a:hover {
    color: var(--orange);
}

/*flex*/
.gap-10 {
    gap: 10px;
}

.d-flex {
    display: flex !important;
}

.flex-wrap {
    flex-wrap: wrap;
}

.align-items-center {
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.mx-auto {
    margin: 0 auto;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.flex-row {
    flex-direction: row;
}

.w-100 {
    width: 100%;
}

.flex-auto {
    flex: auto;
}

.info-pages-main-content {
    margin: 50px 0 80px 0;
    min-height: calc(100vh - 787px);

}

.info-pages-main-content * {
    font-size: 14px;
    line-height: 18px;
}

.info-pages-main-content h1 {
    font-size: 22px;
}

.info-pages-main-content p.privacy-text,
.info-pages-main-content h1,
.info-pages-main-content h2,
.info-pages-main-content h3,
.info-pages-main-content h4,
.info-pages-main-content h5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
}

.info-pages-main-content a {
    color: var(--blue);
    font-weight: 500;
}

.info-pages-main-content p.privacy-text {
    font-size: 18px;
    font-weight: 600;
    margin: 25px 0 15px 0;
}

.info-pages-main-content p {
    margin: 10px 0;
}

.info-pages-main-content ul,
.info-pages-main-content ol {
    padding-left: 0;
    list-style: none;
}

@media (min-width: 1024px) {
    .info-pages-main-content {
        min-height: calc(100vh - 515px);
    }

    .info-pages-main-content * {
        font-size: 16px;
        line-height: 22px;
    }

    .info-pages-main-content h1 {
        font-size: 24px;
        font-weight: 600;
    }

    .info-pages-main-content h2,
    .info-pages-main-content h3,
    .info-pages-main-content h4,
    .info-pages-main-content h5 {
        font-size: 22px;
        font-weight: 500;
        margin-top: 25px;
    }


}

.filed-border:focus,
.filed-border {
    border: 1px solid var(--border) !important;
}

.form-group label,
.field label {
    margin-bottom: 8px;
    display: block;
}

.form-group {
    margin-bottom: 24px;
}

.filed-checkbox input[type="checkbox"] {
    display: none;
}

.filed-checkbox label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
}

.filed-checkbox label:before {
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid red;
    border-radius: 4px;
    display: flex;
    position: absolute;
    top: 2px;
    left: 0;
    font-size: 13px;
    font-weight: 900;
    line-height: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
    background: var(--grey);
}

.filed-checkbox input:checked + label:before {
    content: "\2713";
    background: var(--blue);
    border: 1px solid var(--blue);
}

button:disabled,
.disabled {
    opacity: 0.6;
    pointer-events: none;
}

.min-H {
    min-height: calc(100vh - 515px);
}

.border-none {
    border: none !important;
}

.position-relative {
    position: relative;
}

.cabinet-firms .connected-firm-item .filed-radio{
    font-size: 13px;
    font-weight: 500;
}
.help-article-content p{
    margin: 0;
    font-size: 14px;
}
.help-article-content{
    display: flex;
    flex-direction: column;
    gap: 8px;
}